var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"student-list-container"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.t("Học sinh")))]),_c('crud-buttons',{attrs:{"name":_vm.t('Học sinh'),"selected-rows":_vm.selectedRows,"modal":"modal-student","show-import":_vm.$can('create', 'student'),"show-export":true,"hide-delete":!_vm.$can('delete', 'student'),"enable-add":_vm.$can('create', 'student'),"link-template-file":"https://cdn.resident.vn/attachments/1703816048273_mau-nhap-lieu-hoc-sinh.xlsx"},on:{"on-add":_vm.resetItem,"on-delete":_vm.onDelete,"on-export":_vm.exportData,"on-import-file":_vm.importData}})],1),_c('b-card-body',[_c('b-row',[_c('b-col',[_c('select-school',{attrs:{"label":null},model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}})],1),_c('b-col',[_c('select-class',{attrs:{"school":_vm.school},model:{value:(_vm.classObject),callback:function ($$v) {_vm.classObject=$$v},expression:"classObject"}})],1),_c('b-col',[_c('select-linked-zalo-status',{model:{value:(_vm.isLinked),callback:function ($$v) {_vm.isLinked=$$v},expression:"isLinked"}})],1),_c('b-col',[_c('b-form-input',{attrs:{"placeholder":"Tìm kiếm...","debounce":"1000"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"style-class":"vgt-table striped bordered","mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: false,
            },"select-options":{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: _vm.serverParams.perPage,
            }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-student-detail",modifiers:{"modal-student-detail":true}}],staticClass:"font-weight-bold",on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field === 'linkedOA')?_c('span',[(props.row.zaloContacts.length > 0)?_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-zalo-account",modifiers:{"modal-zalo-account":true}}],on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_c('b-badge',{staticClass:"size-18",attrs:{"pill":"","variant":"light-success"}},[_vm._v(_vm._s(((props.row.zaloContacts.length) + " tài khoản")))])],1):_c('b-badge',{staticClass:"size-18",attrs:{"pill":"","variant":"light-danger"}},[_vm._v(_vm._s("KH chưa liên kết"))])],1):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":_vm.t('Học sinh'),"modal":"modal-student","hide-delete":!_vm.$can('delete', 'student'),"hide-edit":!_vm.$can('update', 'student')},on:{"on-edit":function($event){return _vm.onEditItem(props.row)},"on-delete":function($event){return _vm.onDelete(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Hiển thị tối đa ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":[
                      '10',
                      '20',
                      '50',
                      '100',
                      '500',
                      '1000',
                      '2000' ]},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" trên tổng số "+_vm._s(props.total)+" kết quả ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.serverParams.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("Không có bản ghi nào!")])])],2)],1)],1)],1)],1),_c('student-modal',{attrs:{"item":_vm.item},on:{"refetch-data":_vm.fetchStudents}}),_c('student-detail-modal',{attrs:{"student-id":_vm.item.id}}),_c('zalo-account-modal',{attrs:{"item":_vm.item},on:{"refetch-data":_vm.fetchStudents}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }